import React from 'react'
import PropTypes from "prop-types"
import Img from 'gatsby-image'
import Layout from "../components/Layout"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SEO from "../components/seo"
import default_image from '../images/default_back.jpg'
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox"; 
import { faAllergies } from '@fortawesome/free-solid-svg-icons'
class ProjectsTemplate extends React.Component{
    render(){
        const page = this.props.data.wordpressPage
        let back_image = null ? default_image : page.featured_media.localFile.childImageSharp.resolutions.src;
        let gallery2 = page.acf.galerija_linkovi;
        var expression1 = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/g;
        var expression2 = /alt="(.*?)"[^\>]+>/g;
        var matches1 = gallery2.match(expression1);
        var matches2 = gallery2.match(expression2);
        let all_links = [], all_alts = [];
        let i = 0, j = 0;
        for(var match in matches1)
        {
            var result = {};
            result['id'] = i;
            result['link'] = matches1[match].slice(0,-1);
            i++;
            all_links.push(result);
         }
         for(var match in matches2)
        {
            var result = {};
            result['id'] = j;
            result['alt'] = matches2[match].slice(5).slice(0,-4);
            all_alts.push(result);
            j++;
         }
         
         let all_gallery_data = [];
         for(let i=0; i<all_links.length; i++) {
            all_gallery_data.push({
             ...all_links[i], 
             ...(all_alts.find((itmInner) => itmInner.id === all_links[i].id))}
            );
          }
          
        return(
            <Layout>
            <SEO title={page.title} keywords={[`telekomunikacije`, `inžinjering`]} />
            <div id={"_post_"+page.id} className="post">
                <section className="section page-title gallery-page-title default">
                    <div className="container">
                    <h4 className="subtitle">PROJEKTI</h4>
                    <h1 dangerouslySetInnerHTML={{__html: page.title }} className="title"/>
                   
                    </div>
                </section>

                <section className="page-news-header">
                    <div className="container is-fluid">
                       
                        <div className="is-right share">
                            <a href=""><FontAwesomeIcon icon={['fab', 'facebook-f']} aria-hidden="true" /></a>
                            <a href=""><FontAwesomeIcon icon={['fab', 'twitter']} aria-hidden="true" /></a>
                            <a href=""><FontAwesomeIcon icon={['fas', 'envelope']} aria-hidden="true" /></a>
                        </div>
                    </div>
                </section>

                <section className="section page-content page-gallery">
                   <div className="container is-fluid">
                   {all_gallery_data.length > 1 && (
                     <SimpleReactLightbox>
                     <SRLWrapper>
                         {all_gallery_data.map(image => (
                             <a href={image.link} className="column is-3" data-attribute="SRL">
                             <img src={image.link} alt={image.alt} />
                             <p className="desc">{image.alt}</p>
                             </a>
                         ))}
                        
                     </SRLWrapper>
                 </SimpleReactLightbox>
                   )}
                   {all_gallery_data.length === 1 && (<p className="under-construction">Stranica je trenutno u izradi.</p>)}

                  {/* <h1 dangerouslySetInnerHTML={{__html: page.title }} className="title"/> */}
                        {page.content && (<div dangerouslySetInnerHTML={{__html: page.content }} className="main" />)}
                       {/* {!page.content && (<p className="under-construction">Stranica je trenutno u izradi.</p>)} */}
                   </div>
                </section>

            </div>
            </Layout>
        )
    }
}



export default ProjectsTemplate

export const pageQuery = graphql`
    query currentProjectQuery($id: String!) {
        wordpressPage(id: {eq: $id }){
        title
        content
        slug
        id
        date(formatString: "MMMM DD, YYYY")
        acf{
            galerija_linkovi
        }
        featured_media{
            localFile{
                childImageSharp{
                    resolutions(width:1920, height:500){
                        src
                        width
                        height
                    }
                }
            }
        }
    }
    site{
        id
        siteMetadata {
            title
            subtitle
        }
    }
}
`
